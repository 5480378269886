import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Setting } from '@common/interfaces/setting.interface';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-user-setting-card',
    templateUrl: './user-setting-card.component.html',
    providers: [DatePipe]
})
export class UserSettingCardComponent implements AfterViewInit {
    @Input() setting: Setting;

    get settingValueAsBoolean(): boolean {
        return this.setting.value === 'true';
    }

    set settingValueAsBoolean(value: boolean) {
        this.setting.value = value.toString();
    }

    constructor(
        private cd: ChangeDetectorRef,
        private commonService: CommonService
    ) {}

    ngAfterViewInit(): void {
        this.updateSettingValue(this.setting.value);
        this.cd.detectChanges();
    }

    onThemeChange($event) {
        // this.darkModeService.set($event === 'dark'); // Set theme here?
        this.setting.value = $event;
    }

    updateSettingValue(value: string) {
        this.setting.value = value;
        this.cd.detectChanges();
    }

    deleteImageSetting(setting: Setting) {
        this.commonService.settingsService.deleteImageSetting(setting, true);
    }
}
