<div *subscribe="query$ as query">
    <app-loader [isBusy]="query?.isLoading">
        <ng-template>
            <app-content-header
                [icon]="icon"
                [title]="title"
                [actionBar]="actionBar"
                [currentNavItem]="currentNavItem"
                [breadcrumbs]="breadcrumbs">
                <div
                    class="grid w-full grid-cols-12 gap-x-6 gap-y-2 border-t border-t-background-darker px-4 pb-2 pt-3 text-xs"
                    metadata-2>
                    <div class="col-span-3">
                        <span>{{ 'Username' | translate }}:&nbsp;</span>
                        <span class="font-bold">{{ model.userName }}</span>
                    </div>
                </div>
            </app-content-header>
            <app-card>
                <div class="grid grid-cols-12 gap-4" setEditMode [isEditMode]="editMode">
                    <div class="col-span-3">
                        <app-control label="Full Name" [(ngModel)]="model.fullName" />
                    </div>
                    <div class="col-span-3">
                        <app-control
                            label="Organization"
                            type="codelist"
                            codelist="Organization"
                            [(ngModel)]="model.organizationId" />
                    </div>
                    <div class="col-span-3">
                        <app-control label="E-mail" pattern="[\w\d\.\-\_\@]" [(ngModel)]="model.email" />
                    </div>
                    <div class="col-span-3">
                        <app-control label="Phone Number" [(ngModel)]="model.phoneNumber" />
                    </div>
                    <div class="col-span-3">
                        <app-control
                            label="Language"
                            type="codelist"
                            codelist="Language"
                            [(ngModel)]="model.languageId" />
                    </div>
                </div>
            </app-card>
        </ng-template>
    </app-loader>
</div>
