import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { UserSettingCardComponent } from './components/user-setting-card/user-setting-card.component';
import { UserSettingsListComponent } from './components/user-settings-list/user-settings-list.component';

export const USER_SETTINGS_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: UserSettingsListComponent
    },
    {
        path: '**',
        redirectTo: 'list'
    }
];

@NgModule({
    declarations: [UserSettingsListComponent, UserSettingCardComponent],
    imports: [CommonModule, RouterModule.forChild(USER_SETTINGS_ROUTES)]
})
export class UserSettingsModule {}
