import { Component, OnDestroy } from '@angular/core';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { Setting } from '@common/interfaces/setting.interface';
import { UserSetting } from '@common/interfaces/user-setting.interface';
import { CommonService } from '@common/services/common.service';
import { Breadcrumb } from '@common/services/navigation.service';
import { NavItem } from 'app/navigation';
import { cloneDeep, isEqual } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

export interface UserSettingCategory {
    id: string;
    name: string;
    userSetting?: UserSetting;
}

@Component({
    selector: 'app-user-settings-list',
    templateUrl: './user-settings-list.component.html'
})
export class UserSettingsListComponent implements OnDestroy {
    userSettings: Setting[] = [];
    allSettings: Setting[] = [];
    originalSettings: Setting[] = [];

    breadcrumbs: Breadcrumb[] = [{ title: 'User Settings', icon: 'faSolidGear' }];
    currentNavItem: NavItem = { id: '1', title: 'User Settings', icon: 'faSolidGear' };
    actionBar: ActionBarGroup[] = [
        {
            label: 'Edit Actions',
            items: [
                {
                    label: 'Save Changes',
                    icon: 'faSolidFloppyDisk',
                    onClick: () => this.saveChanges()
                }
            ]
        }
    ];

    destroy$ = new Subject<boolean>();

    constructor(protected commonService: CommonService) {
        this.getUserSettings();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    saveChanges() {
        const changedSettings = this.getChangedSettings();
        if (changedSettings.length === 0) {
            this.commonService.toastrNotificationService.show({ type: 'info', message: 'No changes detected' });
            return;
        }

        this.commonService.settingsService.saveUserSettings(changedSettings).then(() => {
            this.getUserSettings();
            this.commonService.toastrNotificationService.show({ type: 'success', message: 'Save successful' });
        });
    }

    private getUserSettings() {
        this.commonService.queryService
            .getQuery('UserSettingList', {}, { injector: this.commonService.injector })
            .result$.pipe(takeUntil(this.destroy$))
            .subscribe((result: any) => {
                if (!result?.data) return;
                this.allSettings = result.data;
                this.originalSettings = cloneDeep(this.allSettings);
            });
    }

    private getChangedSettings(): Setting[] {
        return this.allSettings.filter((setting: Setting) => {
            const original = this.originalSettings.find((orig) => orig.key === setting.key);
            return !isEqual(original, setting);
        });
    }
}
