<app-content-header [actionBar]="actionBar" [currentNavItem]="currentNavItem" [breadcrumbs]="breadcrumbs" />
@for (item of allSettings; track $index) {
    <div class="mx-auto mt-2 w-3/4 rounded-xl border-0 shadow-md">
        <div class="flex items-center border-b border-gray-200">
            <div class="description flex-1">
                <app-user-setting-card [setting]="item" />
            </div>
        </div>
    </div>
}
