import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.name === "production") {
    enableProdMode();
}

if (environment.name === "local") {
    platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
} else {
    platformBrowserDynamic().bootstrapModule(AppModule);
}
