import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { canDeactivateGuard } from '@common/guards/can-deactivate.guard';
import { ViewMode } from '@common/models/view-mode';
import { UserAccountViewComponent } from './components/user-account-view/user-account-view.component';

export const USER_PROFILE_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'view',
        pathMatch: 'full'
    },
    {
        path: 'edit/:id',
        component: UserAccountViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [canDeactivateGuard]
    },
    {
        path: 'view/:id',
        component: UserAccountViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: '**',
        redirectTo: 'view'
    }
];

@NgModule({
    declarations: [UserAccountViewComponent],
    imports: [CommonModule, RouterModule.forChild(USER_PROFILE_ROUTES)]
})
export class UserAccountModule {}
