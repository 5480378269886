import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { CommonService } from '@common/services/common.service';

export enum AppSettings {
    NoticeBoard = 'noticeBoardEnabled'
}

export const checkFeatureAvailabilityGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const propertyToCheck = route.data['check'];
    const commonService = inject(CommonService);
    const router = inject(Router);
    return commonService.queryService.query('AppSettingFeaturesAvailability', {}).then((response: any) => {
        if (!response[propertyToCheck]) {
            router.navigate(['/']);
            return false;
        }
        return true;
    });
};
