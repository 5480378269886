import { Routes } from '@angular/router';
import { UnauthorizedComponent } from '@common/components/unauthorized.component';
import { AppSettings, checkFeatureAvailabilityGuard } from '@common/guards/check-feature-availability.guard';
import { LoginComponent } from './common/components/login/login.component';
import { LogoutComponent } from './common/components/logout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { UserAccountModule } from './user-account/user-account.module';
import { UserSettingsModule } from './user-settings/user-settings.module';

export const ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '401', component: UnauthorizedComponent },
    { path: 'logout', component: LogoutComponent },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            {
                path: 'administration',
                loadChildren: () =>
                    import('./administration/administration.module').then((m) => m.AdministrationModule),
                data: { title: 'Administration' }
            },
            { path: 'home', component: HomeComponent },
            {
                path: 'user-account',
                loadChildren: () => UserAccountModule,
                data: { title: 'Account' }
            },
            {
                path: 'notice-board',
                loadChildren: () => import('./notice-board/notice-board.module').then((m) => m.NoticeBoardModule),
                canActivate: [checkFeatureAvailabilityGuard],
                data: { title: 'Notifications', check: AppSettings.NoticeBoard }
            },
            {
                path: 'user-settings',
                loadChildren: () => UserSettingsModule,
                data: { title: 'User Settings' }
            },
            {
                path: 'faq',
                loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
                data: { title: 'FAQ' }
            },
            { path: '**', redirectTo: '/home' }
        ]
    }
];
