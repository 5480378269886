import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import _ from 'lodash';
import { BehaviorSubject, NEVER, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, mergeMap, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    private activeRequests = 0;
    private refreshingToken = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private loadingBar: any;

    constructor(
        private errorHandler: ErrorHandler,
        private loadingBarService: LoadingBarService,
        private userService: UserService
    ) {
        this.loadingBar = this.loadingBarService.useRef('http');
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        this.loadingBar.start();

        const refreshToken = this.userService.getRefreshToken();
        req = this.setTokenAndUrl(req, this.userService.getAccessToken());

        this.activeRequests++;

        return next.handle(req).pipe(
            mergeMap((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.Response) {
                    this.decreaseActiveRequests();
                }
                return [event];
            }),
            catchError((error) => {
                this.decreaseActiveRequests();

                if (this.shouldRefreshToken(error)) {
                    return this.refreshToken(req, next, refreshToken);
                }

                if (this.shouldLogout(error)) {
                    // In case the user logged out from another window
                    this.userService.logout();
                    return NEVER;
                }

                if (error?.error?.validationErrors || error?.error?.entityErrors) this.errorHandler.handleError(error);
                return throwError(() => error);
            }),
            finalize(() => {
                this.decreaseActiveRequests();
            })
        );
    }

    private shouldRefreshToken(error: any) {
        return (
            error instanceof HttpErrorResponse &&
            !_.endsWith(error.url, 'RefreshAccessToken') &&
            error.headers.has('Token-Expired') &&
            error.status === 401
        );
    }

    private shouldLogout(error: any) {
        return (
            error instanceof HttpErrorResponse &&
            !_.endsWith(error.url, 'RefreshAccessToken') &&
            !_.endsWith(error.url, 'Login') &&
            !error.headers.has('Token-Expired') &&
            error.status === 401
        );
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler, refreshToken) {
        if (!this.refreshingToken && refreshToken === this.userService.getRefreshToken()) {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            return this.userService.refreshAccessToken().pipe(
                switchMap((token: any) => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject.next(token.accessToken);
                    return next.handle(this.setTokenAndUrl(request, token.accessToken));
                }),
                catchError((error) => {
                    this.refreshingToken = false;
                    // logout only when the refresh token is invalid
                    if ([400, 401].includes(error.status)) {
                        this.userService.logout();
                    }

                    return throwError(() => error);
                })
            );
        }
        return this.refreshTokenSubject.pipe(
            filter((token) => token != null),
            take(1),
            switchMap((token: any) => next.handle(this.setTokenAndUrl(request, token)))
        );
    }

    private setTokenAndUrl(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    private decreaseActiveRequests() {
        this.activeRequests--;

        if (this.activeRequests === 0) {
            this.loadingBar.complete();
        }
    }
}
