import { Component, HostBinding, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/setting-key.codes';
import { User } from '@common/models/user';
import { CommonService } from '@common/services/common.service';
import { WebsocketService } from '@common/services/websocket.service';
import { environment } from '@environments/environment';
import { tapSuccessResult } from '@ngneat/query';
import { SplitterComponent } from '@progress/kendo-angular-layout';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NavItem } from './navigation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnDestroy {
    @ViewChild('consoleSplitter', { static: false }) consoleSplitter: SplitterComponent;
    environmentName = environment.name;
    environmentColor = environment.settings.header.environmentColor;
    gitVersionInfo = environment.gitVersionInfo;
    isAuthenticated = false;
    backgroundImageUrl: SafeUrl;
    currentUser: User;
    @HostBinding('class.dark') get isDarkMode() {
        return this.commonService.darkModeService.get();
    }

    destroy$ = new Subject<boolean>();

    constructor(
        websocketService: WebsocketService,
        public commonService: CommonService
    ) {
        this.commonService.userService.isAuthenticatedSubject.pipe(distinctUntilChanged()).subscribe((value) => {
            this.isAuthenticated = value;
            if (this.isAuthenticated) this.getSettings();
            else this.getLoginSettings();
        });

        this.commonService.userService.currentUserSubject.subscribe((value) => {
            this.currentUser = value;
            if (this.currentUser)
                try {
                    websocketService.info('Client connected');
                    this.commonService.i18nService.setLanguageFromUser(this.currentUser);
                } catch {
                    /* empty */
                }
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    hasPermission(item: NavItem) {
        if (!this.currentUser) return false;

        const permissions = _.isArray(item.permissions) ? item.permissions : item.permissions ? [item.permissions] : [];
        if (!item.permissions || (_.isArray(item.permissions) && item.permissions.length === 0)) {
            return true;
        }

        return this.currentUser.hasPermission(...permissions);
    }

    private handleImageUpload(fileContent: string) {
        const decodedData = atob(fileContent);
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uint8Array[i] = decodedData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'image/jpeg' });
        this.backgroundImageUrl = URL.createObjectURL(blob);
    }

    private getSettings() {
        this.commonService.settingsService
            .getSettings()
            .pipe(
                takeUntil(this.destroy$),
                tapSuccessResult((result: any) => {
                    const appBackgroundImageContent = result.find(
                        (setting: Setting) => setting.key === SettingKeyCodes.AppBackgroundImage
                    )?.value;
                    if (appBackgroundImageContent) this.handleImageUpload(appBackgroundImageContent);
                    else this.backgroundImageUrl = null;
                })
            )
            .subscribe();
    }

    private getLoginSettings() {
        this.commonService.settingsService
            .getLoginSettings()
            .pipe(
                takeUntil(this.destroy$),
                tapSuccessResult((result: Setting[]) => {
                    const loginBackgroundImageContent = result.find(
                        (setting: Setting) => setting.key === SettingKeyCodes.LoginBackgroundImage
                    )?.value;
                    if (loginBackgroundImageContent) this.handleImageUpload(loginBackgroundImageContent);
                    else this.backgroundImageUrl = null;
                })
            )
            .subscribe();
    }
}
