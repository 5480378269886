<div class="bg-white p-4">
    <!-- <button (click)="onClick()">VALIDATE</button>
    <h3 class="text-lg">Text</h3> -->

    <!-- <app-text-input
        label="text-input"
        id="text-input-1"
        [isEditMode]="true"
        [isDisabled]="false"
        [isRequired]="true"
        [maxLength]="10"
        [(value)]="text">
        <span class="textbox-suffix flex items-center px-1">
            <ng-icon size="1rem" name="faSolidAngleRight" />
        </span>
    </app-text-input> -->

    <!-- <h3 class="text-lg">Number</h3>
    <div class="flex gap-2">
        <app-input [value]="value" [config]="numberInput" />

        <app-number-input
            label="number-input"
            id="number-input-1"
            title="nekineki"
            format="#"
            [isEditMode]="true"
            [isRequired]="true"
            [(value)]="value" />
        <app-number-input
            label="disabled number-input"
            id="number-input-2"
            title="nekineki"
            format="#"
            [isDisabled]="true"
            [isEditMode]="true"
            [(value)]="value" />
        <app-number-input
            label="view number-input"
            id="number-input-3"
            title="nekineki"
            format="#"
            [isEditMode]="false"
            [(value)]="value" />
    </div>

    <h3 class="mt-4 text-lg">Boolean</h3>
    <div class="flex gap-2">
        <app-boolean-input
            label="view boolean-input"
            id="boolean-input-1"
            [isEditMode]="false"
            [isDisabled]="true"
            [(value)]="buli" />
        <app-boolean-input
            label="boolean-input"
            id="boolean-input-2"
            [isEditMode]="true"
            [isDisabled]="false"
            [isRequired]="true"
            [(value)]="buli" />
        <app-boolean-input
            label="boolean-input"
            id="boolean-input-3"
            [isEditMode]="true"
            [isDisabled]="true"
            [isRequired]="true"
            [(value)]="buli" />
    </div>

    <h3 class="mt-4 text-lg">Date</h3>
    <app-date-input
        label="date-input"
        id="date-input-1"
        [isEditMode]="true"
        [isDisabled]="false"
        [isRequired]="true"
        [(value)]="date"></app-date-input>
    <app-date-input
        label="view date-input"
        id="date-input-2"
        [isEditMode]="false"
        [isDisabled]="false"
        [(value)]="date"></app-date-input>
    <app-date-input
        label="date-input with min date"
        id="date-input-3"
        [isEditMode]="true"
        [isDisabled]="false"
        [minDate]="minDate"
        [(value)]="date" />
    <app-date-input
        label="date-input with max date"
        id="date-input-4"
        [isEditMode]="true"
        [isDisabled]="false"
        [maxDate]="minDate"
        [(value)]="date" />
-->
    <!-- <h3 class="mt-4 text-lg">Datetime</h3>
    <app-datetime-input
        label="view datetime"
        id="datetime-input-1"
        [isEditMode]="false"
        [isDisabled]="true"
        [(value)]="date"></app-datetime-input>
    <app-datetime-input
        label="datetime"
        id="datetime-input-1"
        [isEditMode]="true"
        [isDisabled]="false"
        [minDate]="minDate"
        [(value)]="date"></app-datetime-input> -->

    <!--     <h3 class="mt-4 text-lg">Daterange</h3>
    <app-daterange-input
        label="datetime"
        id="datetime-input-1"
        [isEditMode]="true"
        [isRequired]="true"
        [isDisabled]="false"
        [(value)]="range" /> -->

    <!-- <h3 class="mt-4 text-lg">File</h3>
    <app-file-input
        label="single file-input"
        id="file-input-1"
        [isEditMode]="true"
        [isDisabled]="false"
        [(value)]="file"></app-file-input>
    <app-file-input
        label="multiple file-input"
        id="file-input-2"
        [isEditMode]="true"
        [isDisabled]="false"
        [isMultiple]="true"
        [(value)]="files"></app-file-input>

    <h3 class="mt-4 text-lg">Select</h3>
    <div>Single</div>
    <app-select-input
        label="select-input"
        id="select-input-1"
        [isEditMode]="true"
        [isDisabled]="false"
        [options]="options"
        [(value)]="selected" />
    <app-select-input
        label="view select-input"
        id="select-input-2"
        [isEditMode]="false"
        [isDisabled]="false"
        [options]="options"
        [(value)]="selected" />

    <div>Single filterable</div>
    <app-select-input
        label="filterable select-input"
        id="select-input-1"
        [isEditMode]="true"
        [isDisabled]="false"
        [options]="options"
        [isRequired]="true"
        [isFilterable]="true"
        [(value)]="selected" />
    <app-select-input
        label="view filterable select-input"
        id="select-input-2"
        [isEditMode]="false"
        [isDisabled]="false"
        [isRequired]="true"
        [options]="options"
        [(value)]="selected" />

    <div>Multiple</div>
    <app-select-input
        label="select-input multiple"
        id="select-input-2"
        [isEditMode]="true"
        [isDisabled]="false"
        [isMultiple]="true"
        [isRequired]="true"
        [options]="options"
        [(value)]="selectedMultiple" />
    <app-select-input
        label="view select-input multiple"
        id="select-input-3"
        [isEditMode]="false"
        [isDisabled]="false"
        [isMultiple]="true"
        [isRequired]="true"
        [options]="options"
        [(value)]="selectedMultiple" />

    <h3 class="mt-4 text-lg">Codelist</h3>
    <div>Single</div>
    <app-codelist-input
        label="codelist-input"
        id="codelist-input-1"
        codelist="Language"
        [isEditMode]="true"
        [isDisabled]="false"
        [isRequired]="true"
        [(value)]="selectedCodelist" />
    <app-codelist-input
        label="view codelist-input"
        id="codelist-input-2"
        codelist="Language"
        [isEditMode]="false"
        [isDisabled]="false"
        [isRequired]="true"
        [(value)]="selectedCodelist" />

    <div>Multiple</div>
    <app-codelist-input
        label="codelist-input multiple"
        id="codelist-input-3"
        codelist="Language"
        [isMultiple]="true"
        [isEditMode]="true"
        [isDisabled]="false"
        [isRequired]="true"
        [(value)]="selectedCodelistMultiple" />
    <app-codelist-input
        label="view codelist-input multiple"
        id="codelist-input-4"
        codelist="Language"
        [isMultiple]="true"
        [isDisabled]="false"
        [isEditMode]="false"
        [isRequired]="true"
        [(value)]="selectedCodelistMultiple" /> -->
</div>
