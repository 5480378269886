import { Component } from '@angular/core';
import { BaseViewComponent } from '@common/classes/base-view';
import { IconKey } from '@common/classes/icons';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-user-account-view',
    templateUrl: './user-account-view.component.html',
    providers: [CommonService]
})
export class UserAccountViewComponent extends BaseViewComponent {
    override title = 'User Account';
    override entityName = 'UserAccount';
    icon: IconKey = 'faSolidCircleUser';

    constructor(common: CommonService) {
        super(common);
        this.actionBar[0].items.push({
            label: 'Change Password',
            icon: 'faSolidBook',
            isVisible: () => this.canChangePassword(),
            onClick: () => this.changePassword()
        });
    }

    canChangePassword() {
        return !this.editMode;
    }

    async changePassword() {
        const data = await this.dialogService.form({
            options: {
                title: 'Change Password',
                properties: [
                    { name: 'oldPassword', label: 'Old Password', type: 'password' },
                    { name: 'newPassword', label: 'New Password', type: 'password' },
                    { name: 'repeatNewPassword', label: 'Repeat New Password', type: 'password' }
                ],
                canConfirm: (model) => model.oldPassword && model.newPassword && model.repeatNewPassword
            }
        });
        if (!data) return;
        this.queryService
            .getCommandMutation()
            .mutateAsync({
                command: 'ChangePassword',
                data: {
                    userName: this.user.userName,
                    ...data
                }
            })
            .then(() => this.loadModel());
    }

    override saveCommand(customData?: {}): Promise<any> {
        this.entityName = 'User';
        return super.saveCommand(customData);
    }

    override getIdentifier = () => this.model.fullName;
}
