import { AfterViewInit, Component, ViewChildren } from '@angular/core';
import { BaseInputComponent } from '@common/components/input/base-input/base-input.component';
import { InputConfig, InputType } from '@common/components/input/input.type';
import { environment } from '@environments/environment';

@Component({
    templateUrl: './home.component.html'
})
export class HomeComponent implements AfterViewInit {
    @ViewChildren(BaseInputComponent) baseInputs;
    logo = environment.settings.header.logo;
    title = environment.title;
    welcomeMessage = environment.settings.header.welcomeMessage;

    text = '';

    value = 0;

    buli = false;

    date = '2024-08-12T12:10:45.123123Z';
    minDate = new Date();

    range = {
        startDate: new Date(),
        endDate: new Date()
    };

    files = [];

    file = [];

    options = [
        { value: '1', label: 'Option 1' },
        { value: '2', label: 'Option 2' },
        { value: '3', label: 'Option 3' }
    ];

    selected = '';
    selectedMultiple = [];

    selectedCodelist = '';

    selectedCodelistMultiple = [];

    numberInput: InputConfig = {
        type: InputType.NUMBER,
        label: 'number-input using app-input',
        title: 'length',
        isEditMode: true,
        value: this.value,
        id: 'numero-neki',
        isRequired: true
    };

    validateLength(value: string) {
        return value.length > 5 ? "That's too long." : null;
    }

    onSubmit(contactForm) {
        console.log('submitted', contactForm.value);
    }

    onClick = () => {
        this.baseInputs.forEach((input) => {
            console.log({
                name: input.id,
                dirty: input.dirty(),
                error: input.error(),
                touched: input.touched()
            });
        });
    };

    ngAfterViewInit() {
        console.log(this.baseInputs);
    }
}
